 const AppReducer = (state, action) =>{  
  

    switch (action.type) {  
        case "ADD_MOVIES_TO_WATCHLIST":
            return {
                ...state,
                watchlist : [action.payload, ...state.watchlist], 
            }
        case "REMOVE_MOVIES_FROM_WATCHLIST":
            return {
                ...state,
                watchlist : state.watchlist.filter(movie=> movie.id !== action.payload)
            }
        case "ADD_MOVIES_TO_WATCHED_LIST":
                return {
                    ...state,
                    watchlist : state.watchlist.filter(movie=> movie.id !== action.payload.id) ,
                    watched :  [action.payload, ...state.watched]
                }
        case "REMOVE_MOVIES_FROM_WATCHED":
            return {
                ...state,
                watched : state.watched.filter(movie=> movie.id !== action.payload)
            }
        case "MOVE_MOVIES_TO_WATCHLIST":
                return {
                    ...state,
                    watched : state.watched.filter(movie=> movie.id !== action.payload.id) ,
                    watchlist :  [action.payload, ...state.watchlist]
                }
        case "ADD_MOVIES_TO_FAVORITES":
                return {
                    ...state,
                    favorites : [action.payload, ...state.favorites]
                }
        case "REMOVE_MOVIES_FROM_FAVORITES":
                    return {
                        ...state,
                        favorites : state.favorites.filter(movie=> movie.id !== action.payload)
                    } 
        case "IS_AUTHORISED":   
            return {  
                ...state, 
                isAuthenticate : action.payload
            }  
        default:
            return state
    }

 }

 export default  AppReducer