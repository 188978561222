import React, { useContext, useEffect, useState } from 'react'; 
import {Offcanvas, Button,Nav, Badge} from 'react-bootstrap'; 
import { Link, NavLink, useLocation} from 'react-router-dom';   
import { GlobelContext } from '../Context/GlobelState';

const HeaderMobile = ({setSearchShow, searchShow, setModalShow}) => { 
  

   const [show, setShow] = useState(false); 
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const location = useLocation();
 

    const handleSearch = ()=>{
        let mainHead = document.querySelector('.mainHead')  
        if(searchShow){ 
           setSearchShow(false)
           mainHead.classList.remove("headSearch")
         
           
        }else{
            setSearchShow(true)  
            mainHead.classList.add("headSearch") 
        }
        
    } 

    useEffect(() => {  
        if(searchShow){ 
           setSearchShow(false)
           const mainHead = document.querySelector('.mainHead');
           mainHead && mainHead.classList.remove("headSearch")
        } 

    }, [location.pathname,setSearchShow]);
    
  const {handleLogout, isAuthenticate, watchlist, watched, favorites} = useContext(GlobelContext)  

    
     
    return (
      <div className="d-flex align-items-center d-lg-none">
        <Link className="mr-2" onClick={handleSearch}>
          <i className="bi bi-search"></i>
        </Link>
        <Button
          className="btn btn-close-white btn-link"
          onClick={handleShow}
        >
          <img
            src="/assets/images/bars-solid.svg"
            style={{ width: "24px" }}
            alt="..."
          />
        </Button>

        <Offcanvas
          className="text-bg-dark "
          show={show}
          onHide={handleClose} 
        >
          <Offcanvas.Header className="bg-gradient bg-success" closeButton>
            {!isAuthenticate ? (
              <Offcanvas.Title  onClick={() => setModalShow(true)}>
                <h5>
                  <strong>Log In</strong>
                </h5>
                <p className="font-weight fs-6 mb-0">For a better experience</p>
              </Offcanvas.Title>
            ) : (
              <Offcanvas.Title>
                <h5>
                  <strong>
                    {isAuthenticate?.displayName
                      ? isAuthenticate?.displayName
                      : "Loading..."}
                  </strong>
                </h5>
                <p className="font-weight fs-6 mb-0">
                  {isAuthenticate?.email ? isAuthenticate?.email : "Loading..."}
                </p>
              </Offcanvas.Title>
            )}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="me-auto">
              <NavLink to="/" className="nav-link"  onClick={handleClose}>
                Home
              </NavLink>
              <NavLink to="StreamingNow" className="nav-link" onClick={handleClose}>
                Streaming Now
              </NavLink>
              <NavLink to="StreamingSoon" className="nav-link" onClick={handleClose}>
                Streaming Soon
              </NavLink>
              {isAuthenticate && (
                <>
                  <NavLink
                    to="Watchlist"
                    className="d-block text-decoration-none nav-link"
                    onClick={handleClose}
                  >
                    <i className="bi bi-bookmark-plus"></i> Watchlist{" "}
                    <Badge bg="success" className="bg-gradient">
                      {watchlist[0]?.length}
                    </Badge>
                  </NavLink>
                  <NavLink
                    to="Watched"
                    className="d-block text-decoration-none nav-link"
                    onClick={handleClose}
                  >
                    <i className="bi bi-bookmarks"></i> Watched{" "}
                    <Badge bg="success" className="bg-gradient">
                      {watched[0]?.length}
                    </Badge>
                  </NavLink>
                  <NavLink
                    to="Favorites"
                    className="d-block text-decoration-none nav-link"
                    onClick={handleClose}
                  >
                    <i className="bi bi-heart"></i> Favorites{" "}
                    <Badge bg="success" className="bg-gradient">
                      {favorites[0]?.length}
                    </Badge>
                  </NavLink>
                  <Link
                    className="nav-link mt-2"
                    onClick={() => {
                        handleLogout()
                        handleClose()
                    }}
                  >
                    Logout <i className="bi bi-box-arrow-right"></i>
                  </Link>
                </>
              )}
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    );
}

export default HeaderMobile;
