import React, { useState, useEffect } from "react";
import { Container} from "react-bootstrap";   
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { baseUrl } from "../../../services/Config"; 
import MovieList from "../../Movies/MovieList"; 
import MovieSkeleton from "../../Movies/MovieSkeleton"; 
import SpinnerCircle from "../../SpinnerCircle"

const StreamingSoon = () => {
  
  
  const [items, setItems] = useState([]);
  const [isLoaded, setisLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); 
  const [hasMore, sethasMore] = useState(true);
  
  
  const fetchData = (setItems,items)=> {  
    try {
    const Movies = `${baseUrl}/3/movie/upcoming?api_key=${process.env.REACT_APP_API_KEY}&page=${currentPage}&region=IN`; 
    axios
        .all([axios.get(Movies)])
        .then(
          axios.spread((one) => {     
              setItems([...items, ...one.data.results]);  
              setisLoaded(true)
            setCurrentPage(currentPage + 1)  
            if(one.data.total_results === items.length){
              sethasMore(false)
            }

            // const notReleased = one.data.results.filter((movie)=>{
            //   const today = new Date().toISOString().slice(0, 10);
            //     return movie?.release_date > today
            // }) 

            // const Released = one.data.results.filter((movie)=>{
            //   const today = new Date().toISOString().slice(0, 10);
            //     return movie?.release_date <= today
            // }) 

            // category === "upcoming"  && setUpcoming(notReleased); 
            // category === "now_playing" && setStreamingNow(Released)
           
          })
        );
    } catch (error) {
      console.log(error);
    }
  };
 
  useEffect(() => {  
  
      fetchData(setItems,items); 
    
  }, []);
 
   
    return ( 
        <>
            <div className='mainHead'>
            <Container>
                <h2 className='text-center text-white mt-4'>Streaming Soon</h2>
            </Container> 
            </div>  


            <InfiniteScroll
                  dataLength={items.length} //This is important field to render the next data
                  next={() => {
                    setTimeout(()=>{  
                      fetchData(setItems, items);
                    },1000)
                  }}
                  hasMore={hasMore}
                  scrollThreshold={0.6}
                  loader={<SpinnerCircle/>}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  } 
                   
                >
             {
               <>
                {!isLoaded && <MovieSkeleton col="2" count={18}  height={245} width={166} classEls="skeltonMobile"/> }
             
                <MovieList
                title=""
                data={[items]}
                col="2" 
                classEl="card-sm" 
                type="basic"
                />
               </>
               
           } 
   </InfiniteScroll> 
        </>
    );
}

export default StreamingSoon;
