import React, { useContext } from "react";
import { GlobelContext } from "../../Context/GlobelState"; 
import { toast } from 'react-toastify';
import "bootstrap-icons/font/bootstrap-icons.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip"; 

const MovieControls = ({ movie, type, isSelected, desableMovie }) => { 
  const {
    removeMoviesFromWatchList,
    addMoviestoWatchedList,
    removeMoviesFromWatched,
    movetoWatcheList,
    addMoviestoWatchList,
    addMoviestoFavorites,
    removeMoviesFromFavorites,
    watchlist,
    watched,
    favorites,
    isAuthenticate
  } = useContext(GlobelContext);

  let storedMovie = watchlist[0]?.find(obj=> obj?.id === movie?.id) 
  const desableWatchlistBTN = storedMovie ? true : false;
 
  let storedMovieWatched = watched[0]?.find(obj=> obj?.id === movie?.id)
  const desableWatchedMovieBTN = storedMovieWatched ? true : false;


  let storedFavorites = favorites[0]?.find(obj=> obj?.id === movie?.id)  
  const desableFavoritesBTN = storedFavorites ? true : false; 

  return (
    <>
      <div className="d-flex justify-content-lg-center movieControls position-absolute w-100">
        {type === "watchlist" && (
          <>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id="Already-watched-tooltip">Already Watched</Tooltip>}
            >
              <button
                className="bg-white border-0 p-1 shadow-sm text-center rounded-start  d-none d-lg-inline-block"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  addMoviestoWatchedList(movie?.docId);
                  toast.dismiss();
                  toast.success("Moved to Watched List");
                }}
              >
                <h6 className="text-gray-900 mb-0 font-weight-bold">
                  <i className="bi bi-eye text-warning"></i>
                </h6>
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="WatchList-tooltip">Remove From WatchList</Tooltip>
              }
            >
              <button
                className="bg-white border-0 p-1 shadow-sm text-center rounded-end  d-none d-lg-inline-block"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault(); 
                  removeMoviesFromWatchList(movie?.docId);
                }}
              >
                <h6 className="text-gray-900 mb-0 font-weight-bold">
                  <i className="bi bi-trash text-danger"></i>
                </h6>
              </button>
            </OverlayTrigger>

            {isSelected && (
            <button className={` border ml-2  d-inline-block d-lg-none multiSelect rounded-circle shadow-sm ${!desableMovie ? 'bg-black border-primary' : 'bg-primary'}`} 
            >
              <h6 className="font-weight-bold mb-0 small text-gray-900">
                 {desableMovie ? <i className="bi bi-check text-white"></i> : <i className="bi bi-circle"></i>} 
              </h6>
            </button>
          )}
          </>
        )}

        {type === "watched" && (
          <>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id="notwatched-tooltip">Not Watched</Tooltip>}
            >
              <button
                className="bg-white border-0 p-1 shadow-sm text-center rounded-start  d-none d-lg-inline-block"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  movetoWatcheList(movie?.docId);
                  toast.dismiss();
                  toast.info("Moved to WatchList");
                }}
              >
                <h6 className="text-gray-900 mb-0 font-weight-bold">
                  <i className="bi bi-eye-slash text-warning"></i>
                </h6>
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id="rmwatched-tooltip">Remove Watched</Tooltip>}
            >
              <button
                className="bg-white border-0 p-1 shadow-sm text-center rounded-end  d-none d-lg-inline-block"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  removeMoviesFromWatched(movie?.docId);
                  toast.dismiss();
                  toast.success("Removed from Watched List");
                }}
              >
                <h6 className="text-gray-900 mb-0 font-weight-bold">
                  <i className="bi bi-trash text-danger"></i>
                </h6>
              </button>
            </OverlayTrigger>
            {isSelected && (
            <button className={` border ml-2  d-inline-block d-lg-none multiSelect rounded-circle shadow-sm ${!desableMovie ? 'bg-black border-primary' : 'bg-primary'}`} 
            >
              <h6 className="font-weight-bold mb-0 small text-gray-900">
                 {desableMovie ? <i className="bi bi-check text-white"></i> : <i className="bi bi-circle"></i>} 
              </h6>
            </button>
          )}
          </>
        )}

        {type === "favorites" && (
          <> 
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="rmFavorites-tooltip">Remove From Favorites</Tooltip>
              }
            >
              <button
                className="bg-white border-0 p-1 shadow-sm text-center rounded-1 d-none d-lg-inline-block"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  removeMoviesFromFavorites(movie?.docmtId);
                  toast.dismiss();
                  toast.success("Removed from Favorites");
                }}
              >
                <h6 className="text-gray-900 mb-0 font-weight-bold">
                  <i className="bi bi-trash text-danger"></i>
                </h6>
              </button>
            </OverlayTrigger>

          {isSelected && (
            <button className={` border ml-2  d-inline-block d-lg-none multiSelect rounded-circle shadow-sm ${!desableMovie ? 'bg-black border-primary' : 'bg-primary'}`} 
            >
              <h6 className="font-weight-bold mb-0 small text-gray-900">
                 {desableMovie ? <i className="bi bi-check text-white"></i> : <i className="bi bi-circle"></i>} 
              </h6>
            </button>
          )}
          </>
        )}
        
      </div>

      {/* add to watchlist control all grids */}

      {type === "basic" && (
        <div className="align-items-end basic-mv-controls d-flex flex-column justify-content-end m-2 position-absolute">
          <>
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id="Favorite-tooltip">{desableFavoritesBTN ? "Remove from Favorites" : "Add to Favorites"}</Tooltip>}
            >
              <button
                className="bg-opacity-25 border-0 border-bottom p-1 shadow-sm text-center"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault(); 
                if (isAuthenticate?.uid) {
                  if (desableFavoritesBTN) {
                     removeMoviesFromFavorites(storedFavorites?.docmtId);
                     toast.dismiss();
                     toast.success("Removed from Favorites");
                  }
                  else{
                    addMoviestoFavorites(movie); 
                    toast.dismiss();
                     toast.success("Added to Favorites");
                  } 
                }  else{
                  toast.info("Please Sign-In and continue");
                }
                }}
              >
                <h6 className=" mb-0"> 
                  <i className={`bi ${desableFavoritesBTN ? "bi-heart-fill" : "bi-heart"} text-danger`}></i>
                </h6>
              </button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="add-WatcheList-tooltip">{!desableWatchedMovieBTN ? (desableWatchlistBTN ? "Remove from WatchList" : "Add to WatcheList") : 'Already Watched'}</Tooltip>
              }
            >
              <button
                className="bg-opacity-25 border-0 p-1 shadow-sm text-center"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault(); 
                if (isAuthenticate?.uid) {
                  if (!desableWatchedMovieBTN) {

                    if (desableWatchlistBTN) {  
                        removeMoviesFromWatchList(storedMovie?.docId);
                        toast.dismiss();
                        toast.success("Removed from Watch List");
                    }
                    else{ 
                      addMoviestoWatchList(movie); 
                      toast.dismiss();
                       toast.success("Added to Watch List");
                    } 
                   
                 }  else{
                  toast.warn("Already in watched List");
                }
                } else{
                  toast.info("Please Sign-In and continue");
                }
                  

                }}
              >
                <h6 className="text-gray-900 mb-0 font-weight-bold">
                {!desableWatchedMovieBTN ? (desableWatchlistBTN ? <i className="bi bi-bookmark-fill text-success"></i> : <i className="bi bi-bookmark-plus text-success"></i>) :  <i className="bi bi-check-circle-fill text-success"></i>}
                  
                </h6>
              </button>
            </OverlayTrigger>
          </>
        </div>
      )}
    </>
  );
};

export default MovieControls;
