import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../services/Config";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { Form, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import "./Search.css";

const Search = ({isSearch}) => {
  const [item, setItem] = useState([]);
  const [input, setInput] = useState(''); 
  const [sort, setSort] = useState('All'); 
  const navigate = useNavigate();
 
  
  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/3/search/multi?api_key=${
          process.env.REACT_APP_API_KEY
        }&language=en-IN&region=IN&query=${
          input ? input : ""
        }&page=1`
      );   
        
      const newItem =  data?.results.map(({title : name, ...rest})=>({name, ...rest}))  
            .filter((item)=> sort !== "All" ? item?.media_type === sort : true)
      
      setItem(newItem);
    } catch (error) {
      console.log(`"ERROR : " ${error}`);
    }
  };

  useEffect(() => {

    fetchData();

  }, [input, sort]);
 
   

  const handleOnSearch = (string) => {
    setInput(string);
  };

  const handleOnSelect = (item) => {   
        if (item?.media_type === "person") {
            navigate(`Person/${item?.id}`, {
                state: {
                data: item
                }
            }); 
        }
        else{
            navigate(`movie/${item?.id}`, {
                state: {
                data: item,
                }
            }); 
        }
  };

  const sortHandle =(e)=>{ 
    setSort(e.target.value)
     

  } 

  const formatResult = (item) => { 
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>
          {" "}
          {item ? item?.title || item?.name : ""}{" "}
          <small className="text-warning"> 
            {" "}
            {item
              ? item?.first_air_date?.substring(0, 4) ||
              item?.release_date?.substring(0, 4)
              : ""}
            {item?.media_type === "person" ? (
              <FontAwesomeIcon icon={faCrown} />
            ) : (
              ""
            )}
          </small>
        </span>
      </>
    );
  };



  return ( 
    
    <Container className={isSearch ? `d-block` : `d-none d-lg-block`}>
      <div className="mb-3 main-search mt-4">
        <Form.Select aria-label="Default select example" className="rounded-0" onChange={sortHandle}>
            <option value="All">Sort by</option>
            <option value="movie">movie</option>
            <option value="tv">tv</option>
            <option value="person">person</option>
        </Form.Select>
        <ReactSearchAutocomplete
          items={item}
          fuseOptions={{ keys: ["name"] }} 
          resultStringKeyName={"name"}
          onSelect={handleOnSelect}  
          placeholder={sort !== "All" || '' ? `Search for a ${sort}'s...` : "Search for a movie, tv show, person......" }  
          formatResult={formatResult}
          onSearch={handleOnSearch}   
           
        />
      </div>
    </Container>
  );
};

export default Search;
