import React, { useContext } from 'react'; 
import {Nav, Badge} from 'react-bootstrap'; 
import { NavLink} from 'react-router-dom';   
import { GlobelContext } from '../Context/GlobelState';


const StickyBottom = () => {

    
  const {isAuthenticate, watchlist, watched, favorites} = useContext(GlobelContext)  

    return (
        <div className="sticky-bottom d-block d-lg-none bg-dark">
            <div className='stickyBTM'>
            <Nav className="d-flex justify-content-evenly">
            {isAuthenticate && (
                <>
                  <NavLink
                    to="Watchlist"
                    className="text-decoration-none nav-link d-grid text-center text-white" 
                  >
                    <i className="bi bi-bookmark-plus"></i> 
                    <span>
                    Watchlist{" "}
                    <Badge bg="dark" className="bg-gradient">
                      {watchlist[0]?.length}
                    </Badge>
                    </span>
                  </NavLink>
                  <NavLink
                    to="Watched"
                    className="text-decoration-none nav-link d-grid text-center text-white" 
                  >
                    <i className="bi bi-bookmarks"></i> 
                    <span>
                    Watched{" "}
                    <Badge bg="dark" className="bg-gradient">
                      {watched[0]?.length}
                    </Badge>
                    </span>
                  </NavLink> 
                  <NavLink
                    to="Favorites"
                    className="text-decoration-none nav-link d-grid text-center text-white" 
                  >
                    <i className="bi bi-heart"></i>
                    <span>
                    Favorites{" "}
                    <Badge bg="dark" className="bg-gradient">
                      {favorites[0]?.length}
                    </Badge>
                    </span> 
                  </NavLink>
                </>
              )}
            </Nav>
            </div>
        </div>
    );
}

export default StickyBottom;
