import React, {lazy, Suspense, useEffect, useState} from 'react';
import axios from 'axios';
import AddPost from './AddPost'; 
import "bootstrap-icons/font/bootstrap-icons.css";
const SinglePost = lazy(()=> import('./SinglePost'))

const FirebaseTest = () => {

    const [data, setData] = useState([])
    const [isLoaded, setIsloaded] = useState(false)
    const [showPost, setShowPost] = useState(false)
    const [postdetails, setPostdetails] = useState(null) 
     

    const getFirebasedata = ()=>{
        axios.get('https://movie-watchlist-cca6a-default-rtdb.firebaseio.com/posts.json')
        .then(res =>{  

            const posts = []

            for (let key in res.data) {
                posts.push({...res.data[key], id: key})
            } 
            setData(posts)
            setIsloaded(true)
             
        })
    }

    const deleteFirebasedata = (id)=>{ 
       if (window.confirm('Are you sure want to delete?')) {
            axios.delete(`https://movie-watchlist-cca6a-default-rtdb.firebaseio.com/posts/${id}.json`)
            .then(res =>{  
                getFirebasedata()
            })
       }
    } 

 
    useEffect(()=>{ 
       getFirebasedata() 
       
    },[])


    return ( 
    <>
        <div className='mainHead'>
            <div className='container'>
                <h2 className='text-center text-white mt-4 display-6'>Fire Base Data</h2>
                <p className='text-center'><span className='bg-gradient fs-6 p-2 text-white'>Test Data</span></p>
            </div> 
        </div> 
        <div className='container'>
            <div className='row  mt-3'>
                <div className='col-6'>
                    <div className="row">
                    {
                  isLoaded ?  
                  data.map(item=>{
                   return ( 
                    <div className='border p-2 col-6' key={item.id}>
                         <button onClick={()=>deleteFirebasedata(item.id)} className="btn-sm btn float-end" type="submit">
                            <em className='bi bi-trash text-danger'></em>
                         </button>
                        <a href="*"  onClick={(e)=>{e.preventDefault(); setPostdetails(item.id)}} className="d-block text-decoration-none small text-truncate text-black">
                        <strong>Title</strong> : {item.title}
                        {" "}<br/>
                        <strong>Description</strong> : {item.description}
                        </a>
                    </div>
                   )
                  }) : "Loading...."

                }
                    </div>
                </div>
                <div className='col-6'> 
                    <Suspense fallback={<span>Loading....</span>}>
                        {
                        postdetails && <SinglePost id={postdetails}/>
                        }
                    </Suspense>

                    {
                        showPost ? <AddPost onPostAded={getFirebasedata}/> : <button className='btn btn-primary btn-sm' onClick={()=>setShowPost(true)}>Add New Post</button>
                    }
                    
                </div> 
            </div> 
        </div>
    </> 
    );
}

export default FirebaseTest;
