import React, { useEffect, useState, useContext } from "react";
import { GlobelContext } from "../Context/GlobelState";
import { useParams, useLocation, Link, Navigate } from "react-router-dom"; 
import { toast } from 'react-toastify';
import axios from "axios";
import Persons from "../Pages/Casting"
import { baseUrl, imagePath } from "../../services/Config";
import { FaStar } from "@react-icons/all-files/fa/FaStar";
import YouTube from "react-youtube"
import Skeleton from "react-loading-skeleton";
import "./movie.css";
import "bootstrap-icons/font/bootstrap-icons.css"; 
import YouTubeModal from "./YouTubeModal";    

const Single = () => {
   
   

  const location = useLocation();
  const data = location?.state?.data;

 
  const { matchId } = useParams();
  const [credit, setCredit] = useState();
  const [provider, setProvider] = useState();
  const [video, setVideo] = useState();
  const [match, setMatch] = useState();
  const [seasons, setSeasons] = useState();
  const [isLoaded, setisLoaded] = useState(false);  
  const [modalShow, setModalShow] = useState(false);
     
  const {
    addMoviestoWatchList,
    removeMoviesFromWatchList,
    watchlist,
    watched,
    isAuthenticate,
    favorites,
    addMoviestoFavorites,
    removeMoviesFromFavorites
  } = useContext(GlobelContext); 
  

  let storedMovie = watchlist[0] && watchlist[0].find(obj=>  obj?.id === match?.id) 
  const desableWatchlistBTN = storedMovie ? true : false;
 
  let storedWatched = watched[0] && watched[0]?.find(obj=> obj?.id === match?.id)  
  const desableWatchedBTN = storedWatched ? true : false;

  let storedFavorites = favorites[0] && favorites[0]?.find(obj=> obj?.id === match?.id)   
  const desableFavoritesBTN = storedFavorites ? true : false;
 

  useEffect(() => { 
    window.scrollTo(0, 0); 
    
    const credits = `${baseUrl}/3/${data?.media_type ? data?.media_type : "movie"}/${matchId}/credits?api_key=${process.env.REACT_APP_API_KEY}&language=en-IN`;
    
    const provider = `${baseUrl}/3/${data?.media_type ? data?.media_type : "movie"}/${matchId}/watch/providers?api_key=${process.env.REACT_APP_API_KEY}`;
    
    const mvTrailer = `${baseUrl}/3/${data?.media_type ? data?.media_type : "movie"}/${matchId}/videos?api_key=${process.env.REACT_APP_API_KEY}&language=en-IN`;

    const mvData = `${baseUrl}/3/${data?.media_type ? data?.media_type : "movie"}/${matchId}?api_key=${process.env.REACT_APP_API_KEY}&language=en-US`; 
  
    const fetchData = () => {
      try {
        axios
          .all([axios.get(credits), axios.get(provider), axios.get(mvTrailer), axios.get(mvData)])
          .then(
            axios.spread((credts, providers, mvTrailer, mvData) => {  
              setCredit(credts?.data) 
              setProvider(providers?.data?.results)  
              setVideo(mvTrailer?.data?.results)  
              data?.media_type === "tv" && setSeasons(mvData?.data?.seasons)
              if (!match) {
                setMatch(mvData?.data)   
              }
                setisLoaded(true) 
            })
          );
      } catch (error) {
        console.log(error);
      }
    };

    if(data){ 
      setMatch(data)
    }
      fetchData(); 
      setisLoaded(true)
    
  }, [matchId, match, data]);

  const copyToClipBoard = async copyMe => {
    try {
        await navigator.clipboard.writeText(copyMe);
        toast.success('Link Copied!');
    } 
    catch (err) {
      toast.warn('Failed to copy link!');
    }
 };
    
  if (!match) {
    return null;
}

  const rating = match?.vote_average?.toString().slice(0, 3); 

  const filterSeason = seasons && seasons.filter(item=>item.air_date !== null)

  const lastSeason = filterSeason && filterSeason[filterSeason.length - 1]
  

 const renderTrailer = ()=>{
      const trailer = video.filter(vid=> vid.type.toLowerCase() === "Trailer".toLowerCase())[0]
      const opts = { 
        width: '100%',
        playerVars: { 
          autoplay: 1,  
        },
      };
     
      return  trailer && <YouTube opts={opts} videoId={trailer?.key} config={{ youtube: { playerVars: { origin: 'https://www.youtube.com' } } }} className="YoutubeModal"/>
 }
   
  return (
    <>
      <div
        className="mainHead singleMovie"
        style={{
          backgroundImage: `url(${
            match?.backdrop_path
              ? imagePath + match?.backdrop_path
              : "/assets/images/slider-bg.jpg"
          })`,
        }}
      >
        <div className="content">
          <div className="container">
            <div className="row mt-4">
              <div className="col-md-3 col-sm-12 col-12">
                <div className="row">
                  <div className="col-6 col-lg-12">
                        <div className="movie-img sticky-sb">
                        {isLoaded && (
                          <img
                            src={
                              match?.poster_path
                                ? imagePath + match?.poster_path
                                : "/assets/images/not-available-poster.jpg"
                            }
                            className="img-fluid rounded-top"
                            alt={match?.title || match?.name}
                          />
                        )}
                        {!isLoaded && <Skeleton height={390} width={261} />}
                      </div>
                      {video && video.length !== 0 && (
                        <>
                          <button className="btn btn-success btn-sm mb-2 px-lg-4 rounded-0 w-100 rounded-bottom"  onClick={() => setModalShow(true)}> Watch Trailer Now</button>
                      <YouTubeModal   
                              show={modalShow}
                              onHide={() => setModalShow(false)} 
                              rendertrailer={()=>renderTrailer()}
                              /> 
                        </>
                      )}
                  </div>
                  <div className="col-6 col-lg-12">
                  <div className="row mt-4 d-grid justify-content-around gy-3 text-center d-lg-flex gy-lg-0 mt-lg-2">
                        <div className="col-auto">
                          <div className="social-btn">
                            <div
                              className={`text-white text-decoration-none d-inline-block text-center ${
                                !desableWatchedBTN && "cursorPointer"
                              }`}
                              onClick={() => {
                                if (isAuthenticate) {
                                  if (!desableWatchedBTN) {
                                    if (desableWatchlistBTN) {
                                      removeMoviesFromWatchList(
                                        storedMovie?.docId
                                      );
                                    } else {
                                      addMoviestoWatchList(match);
                                      toast.dismiss();
                                      toast.success("Added to WatchList");
                                    }
                                  } else {
                                    toast.dismiss();
                                    toast.info("Already Watched");
                                  }
                                } else {
                                  toast.info("Please Sign-In and continue");
                                }
                              }}
                            >
                              {!desableWatchedBTN ? (
                                desableWatchlistBTN ? (
                                  <i
                                    className="bi bi-check2  font-weight display-6"
                                    style={{ lineHeight: "0px" }}
                                  ></i>
                                ) : (
                                  <i
                                    className="bi bi-plus font-weight display-6"
                                    style={{ lineHeight: "0px" }}
                                  ></i>
                                )
                              ) : (
                                <i
                                  className="bi bi-check2-circle text-success  font-weight display-6"
                                  style={{ lineHeight: "0px" }}
                                ></i>
                              )}{" "}
                              <span className="d-block small">
                                {!desableWatchedBTN
                                  ? desableWatchlistBTN
                                    ? "Watch List"
                                    : "Watch List"
                                  : "Watched"}{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className="social-btn">
                            <div
                              className={`text-white text-decoration-none d-inline-block text-center cursorPointer mt-2`}
                              onClick={() => {
                                if (isAuthenticate) {
                                  if (desableFavoritesBTN) {
                                    removeMoviesFromFavorites(
                                      storedFavorites?.docmtId
                                    );
                                    toast.dismiss();
                                    toast.success("Removed from Favorites");
                                  } else {
                                    addMoviestoFavorites(match);
                                    toast.dismiss();
                                    toast.success("Added to Favorites");
                                  }
                                } else {
                                  toast.info("Please Sign-In and continue");
                                }
                              }}
                            >
                              {desableFavoritesBTN ? (
                                <i
                                  className="bi bi-heart-fill fs-3  font-weight"
                                  style={{ lineHeight: "0px" }}
                                ></i>
                              ) : (
                                <i
                                  className="bi-heart fs-3 font-weight"
                                  style={{ lineHeight: "0px" }}
                                ></i>
                              )}{" "}
                              <span className="d-block small">Favorites</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className="social-btn">
                            <div
                              className={`text-white text-decoration-none d-inline-block text-center cursorPointer mt-2`} onClick={(e)=>copyToClipBoard(window.location.href)} >
                              
                                <i
                                  className="bi bi-share fs-3  font-weight"
                                  style={{ lineHeight: "0px" }}
                                ></i> 
                              <span className="d-block small">Share</span>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div> 
               
              </div>
              <div className="col-md-9 col-sm-12 col-xs-12">
                <div className="movie-single-ct main-content">
                  <h1 className="bd-hd">
                    {match?.title || match?.name || <Skeleton />}{" "}
                    <span>
                      {match?.release_date?.substring(0, 4) ||
                        match?.first_air_date?.substring(0, 4)}
                    </span>
                  </h1>

                  <div className="row">
                    <div className="col-12 col-lg-10">
                      <p className="small text-white">
                        {isLoaded ? match?.overview : <Skeleton count={4} />}
                      </p>
                      {match?.release_date && (
                        <div className="text-white-50">
                          Release Date :{" "}
                          <span className="text-white">
                            {match?.release_date}
                          </span>
                        </div>
                      )}
                      <div className="movie-rate">
                        <div className="rate">
                          <i className="ion-android-star"></i>
                          {rating !== "0" && (
                            <p className="d-flex align-items-center mt-2">
                              <FaStar className="text-warning fs-6" />
                              <span className="fs-5  ml-2 text-white">
                                {rating}
                              </span>{" "}
                              /10
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                            
                  <div className="row">
                  {credit &&
                      credit?.crew &&
                      credit?.crew.length !== 0 && (
                      <div className="col-lg-12 col-12 table-responsive">
                        <table className="table-borderless table-sm text-white small table custom-table">
                          <tbody>
                            <tr>
                            {
                              credit?.crew.filter((obj) => {   
                                  return (    
                                     obj.job === "Director" || obj.job === "Original Music Composer" || obj.job === "Writer" 
                                  );
                                })
                                .sort((a,b)=>a.job > b.job ? 1 : -1)
                                .map((crew, index) =>   
                                            <td className="p-0"  key={index}>
                                          <span className="d-block text-success">
                                            {crew?.job}
                                            </span>
                                            <Link
                                      to={`/Person/${crew?.id}`} 
                                      className="text-decoration-none text-white"
                                    ><strong>{crew?.name}</strong></Link>
                                        </td>  
                                )
                                }
                                      </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>

                  <div className="row"> 
                    <div className="col-lg-8 col-10">
                      <div className="row ">
                        {provider &&
                          provider?.IN?.flatrate &&
                          provider?.IN?.flatrate.map((provider) => {
                            return (
                              <div
                                className="col-12 col-lg-6"
                                key={provider?.provider_id}
                              >
                                <button
                                  style={{ cursor: "default" }}
                                  className="btn btn-outline-dark d-flex gap-2 mb-2 w-100"
                                  onClick={() => {
                                    toast.warn("Link is not available");
                                  }}
                                >
                                  <img
                                    className="img-thumbnail rounded-5"
                                    src={imagePath + provider?.logo_path}
                                    style={{ width: "48px" }}
                                    alt=""
                                  />
                                  <div className="d-grid text-white">
                                    <span className="font-weight-bold small">
                                      {" "}
                                      Now Streaming On
                                    </span>
                                    <small className="font-weight">
                                      {provider?.provider_name}
                                    </small>
                                  </div>
                                </button>
                              </div>
                            );
                          })}

                        {provider &&
                          provider?.IN?.buy &&
                          provider?.IN?.buy.map((provider) => {
                            return (
                              <div
                                className="col-12  col-lg-6"
                                key={provider?.provider_id}
                              >
                                <button
                                  onClick={() => {
                                    toast.warn("Link is not available");
                                  }}
                                  style={{ cursor: "default" }}
                                  className="btn  btn-outline-dark  d-flex gap-2 mb-2 w-100"
                                >
                                  <img
                                    className="img-thumbnail rounded-5"
                                    src={imagePath + provider?.logo_path}
                                    style={{ width: "48px" }}
                                    alt=""
                                  />
                                  <div className="d-grid text-white">
                                    <span className="font-weight-bold small">
                                      {" "}
                                      Available to Rent/Buy
                                    </span>
                                    <small className="font-weight">
                                      {provider?.provider_name}
                                    </small>
                                  </div>
                                </button>
                              </div>
                            );
                          })}
                      </div>

                     
                    </div>
                    <div className="row g-3">
                      {credit?.cast && (
                        <Persons
                          title="Top Billed Cast"
                          data={[credit.cast]}
                          col="2"
                          count="12"
                          classEl="card-person"
                          type="basic"
                        />
                      )}
                    </div>
                    {lastSeason && (
                      <div className="row g-3">
                            <div className="row g-3">
                              <h6 className="mt-2 text-white bold mb-0 mt-3">
                              Last Season
                              </h6>
                              
                           
                              <div
                              className="col-12 col-lg-10"
                              key={lastSeason?.id}
                            >
                              <div
                                style={{ cursor: "default" }}
                                className="d-flex gap-3 mb-2 w-100"
                                onClick={() => { Navigate(`/movie/${lastSeason?.id}`, {
                                  state: { data: seasons },
                                });
                          }}
                              >
                                <div>
                                <img
                                  className="img-fluid rounded" 
                                  style={{ minWidth: "100px", maxWidth: "100px" }}
                                  src={lastSeason?.poster_path && imagePath + lastSeason?.poster_path}
                                      alt={lastSeason?.name}
                                />
                                </div>
                                <div className="d-grid text-white">
                                  <span className="font-weight-bold">
                                  {lastSeason?.name} 
                                  </span>
                                  <small className="font-weight">
                                  {lastSeason?.episode_count && (<span><strong>{lastSeason?.episode_count}</strong> Episodes | {lastSeason?.air_date?.toString().slice(0, 4) }</span>)} 
                                  </small> 
                                  <p className="fst-normal small text-white-50">{lastSeason?.overview}</p>
                                  
                                </div>
                              </div>
                            </div> 
                            
                              <Link  
                              to={`/season/${matchId}`}
                              state={{ data: seasons, match: match }}
                              className="fst-normal small text-decoration-none text-white">View All Seasons</Link>
                            </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );  
   
      
};

export default Single;
