import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../services/Config";
import MovieList from "./Movies/MovieList";
import Banner from "./Banner/Banner";
import MovieSkeleton from "./Movies/MovieSkeleton";

const Home = () => {
  const [upcoming, setUpcoming] = useState([]);
  const [streamingNow, setStreamingNow] = useState([]);
  const [toprated, setToprated] = useState([]);
  const [popular, setPopular] = useState([]);
  const [tvToday, setTvToday] = useState([]);
  const [isLoaded, setisLoaded] = useState(true);

  useEffect(() => {
    const upcoming = `${baseUrl}/3/movie/upcoming?api_key=${process.env.REACT_APP_API_KEY}&page=1&region=IN`;

    const streamingNow = `${baseUrl}/3/movie/now_playing?api_key=${process.env.REACT_APP_API_KEY}&page=1&region=IN`;

    const topRated = `${baseUrl}/3/movie/top_rated?api_key=${process.env.REACT_APP_API_KEY}&page=1&region=IN`;

    const popular = `${baseUrl}/3/movie/popular?api_key=${process.env.REACT_APP_API_KEY}&language=en-IN&page=1&region=IN`;
    
    const tvAiringToday = `${baseUrl}/3/tv/airing_today?api_key=${process.env.REACT_APP_API_KEY}&language=en-IN&page=1&region=IN`;
    
    const fetchData = async () => {
      try {
        await axios
          .all([axios.get(upcoming), axios.get(streamingNow), axios.get(topRated), axios.get(popular), axios.get(tvAiringToday)])
          .then(
            axios.spread((one, second, two, three, four) => {
              setUpcoming(one.data);
              setStreamingNow(second.data)
              setToprated(two.data);
              setPopular(three.data);
              setTvToday(four.data);
              setisLoaded(false);
            })
          );
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="mainHead">
        <Banner />
      </div>

      {isLoaded && (
        <MovieSkeleton
          col="2"
          count={6}
          title="Now on Theaters"
          height={245}
          width={166}
        />
      )}

      <MovieList
        title="Now on Theaters"
        data={[streamingNow.results]}
        col="2"
        count="12"
        classEl="card-sm"
        type="basic"
      />
      

      {isLoaded && (
        <MovieSkeleton
          col="2"
          count={6}
          title="Upcoming Releases"
          height={245}
          width={166}
        />
      )}

      <MovieList
        title="Upcoming Releases"
        data={[upcoming.results]}
        col="2"
        count="12"
        classEl="card-sm"
        type="basic"
      />

    {isLoaded && (
        <MovieSkeleton
          col="2"
          count={6}
          title="OTT Today"
          height={245}
          width={166}
        />
      )}
      <MovieList
        title="OTT Today"
        data={[tvToday.results]}
        col="2"
        count="12"
        classEl="card-sm"
        type="basic"
      />

    {isLoaded && (
        <MovieSkeleton
          col="2"
          count={6}
          title="Top Popular"
          height={245}
          width={166}
        />
      )}
      <MovieList
        title="Top Popular"
        data={[popular.results]}
        col="2"
        count="6"
        classEl="card-sm"
        type="basic"
      />

      {isLoaded && (
        <MovieSkeleton
          col="2"
          count={12}
          title="Top Rated"
          height={245}
          width={166}
        />
      )}
      <MovieList
        title="Top Rated"
        data={[toprated.results]}
        col="2"
        count="12"
        classEl="card-sm"
        type="basic"
      />

     
    </>
  );
};

export default Home;
