import React from "react";
import Skeleton from "react-loading-skeleton";
import { Container, Card, Row, Col } from "react-bootstrap";

const MovieSkeleton = ({ title, count, col, width, height,classEls }) => { 
  return (
    <Container>
      <h3 className="mt-3">{title}</h3> 
      <Row className="mt-4"> 
        {Array(count)
          .fill(0)
          .map((item, index) => (
            <Col xs="4" lg={col} className="mb-4" key={index}>
              <Card className={`shadow border-0 overflow-auto ${classEls}`}>
                <Skeleton height={height} width={width} />
                <Card.Body>
                  <Card.Title> 
                    <Skeleton />
                  </Card.Title>
                  <Card.Text>
                    <small>
                      <Skeleton />
                    </small>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>
    </Container>
  );
};

export default MovieSkeleton;
