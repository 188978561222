import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { imagePath } from "../../../services/Config";

function index(props) {
  const [data] = props.data;
  return (
    <Container>
      <h6 className="mt-2 text-white bold">{props?.title}</h6>
      {(!data || data.length === 0) && (
        <>
          <h6 className="text-white bold mt-4 h5">No item found</h6>
        </>
      )}
      <div
        className={`${
          props?.classEl === "card-person"
            ? "horizontal_media_list gap-3 mt-3"
            : "mt-4 row"
        }`} 
      >
        {data
          ?.filter((item, idx) => { 
           return item.profile_path && idx < props.count
          })
          .map((item) => {
            return (
              <Col
                xs="4"
                lg={props.col}
                className={props?.classEl === "card-person" ? "" : "mb-4"}
                key={item?.id}
              >
                <Link
                  to={`/Person/${item?.id}`}
                  state={{ data: item }}
                  className="text-decoration-none text-dark"
                >
                  <Card
                    className={`shadow border-0 overflow-hidden ${props.classEl}`}
                  >
                    <Card.Img
                      className="card-img-top rounded-top"
                      src={
                        item?.profile_path
                          ? imagePath + item?.profile_path
                          : "http://busterhtml.mbkip3ms9u-e92498n216kr.p.temp-site.link/images/uploads/slider3.jpg"
                      }
                      alt={item?.name}
                    />
                    <Card.Body className="p-2">
                      <Card.Title>{item?.name}</Card.Title>
                      <Card.Text>
                        {item?.character && (
                          <small className={`small text-black-50`}>
                            {item?.character}
                          </small>
                        )}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            );
          })}
      </div>
    </Container>
  );
}

export default index;
