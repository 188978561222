import { signOut } from "firebase/auth";
import { addDoc, collection, deleteDoc, doc,onSnapshot,  serverTimestamp, updateDoc, writeBatch, WriteBatch } from "firebase/firestore";
import React, { useReducer, createContext, useEffect, useState } from "react"; 
import { toast } from "react-toastify";
import { auth, db } from "../../Firebase";
import AppReducer from "./AppReducer";
import Spinner from "../Spinner"; 

 

// initial state
const initialState = {
  watchlist: [],
  watched: [],
  favorites: [], 
  isAuthenticate : null, 
};

// Create Context
export const GlobelContext = createContext(initialState);

 



 
// Globel Companents

export const GlobelProvider = (props) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);   
  const [loading, setLoading] = useState(true); 
  

  useEffect(() => {    
        const unSub =   auth.onAuthStateChanged((authUser)=>{    
          if(authUser){
             dispatch({ type: "IS_AUTHORISED", payload: authUser })
             setLoading(false)
          }  
          
        })    

          
        const unSubscribeWatchlistStateListener = onSnapshot(
          collection(db, "watchlist"),
          (snapshot) => {
            if (snapshot.docs.length > 0) {
              let watchList = [];
              let watchedList = [];
              snapshot.docs.forEach((doc) => {
                if (doc.data().userId === state.isAuthenticate?.uid) {
                  if (doc.data().watched === true) {
                    watchedList.push({ docId: doc.id, ...doc.data() });
                  } else {
                    watchList.push({ docId: doc.id, ...doc.data() });
                  }
                }
              });
              dispatch({
                type: "ADD_MOVIES_TO_WATCHED_LIST",
                payload: watchedList,
              });
              dispatch({ 
                type: "ADD_MOVIES_TO_WATCHLIST",
                payload: watchList 
              });
              setLoading(false);
            }
          },
          (error) => {
            console.log(error);
          }
        );

        const unSubscribeFavoritesStateListener = onSnapshot(
          collection(db, "favorites"),
          (snapshot) => {
            if (snapshot.docs.length > 0) {
              let favorites = []; 
              snapshot.docs.forEach((doc) => { 
                if (doc.data().userId === state.isAuthenticate?.uid) {
                  favorites.push({ docmtId: doc.id, ...doc.data() });
                }
              }); 
              dispatch({ 
                type: "ADD_MOVIES_TO_FAVORITES",
                payload: favorites 
              });
              setLoading(false);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      
    
            
        return ()=>{ 
          unSub() 
          unSubscribeWatchlistStateListener()  
          unSubscribeFavoritesStateListener()
        }

  }, [state.isAuthenticate]);
  

  if(loading){ 
    return <Spinner/>
  }
 
  
  //actions
  const addMoviestoWatchList = (movie) => {  
      try {
        addDoc(collection(db, "watchlist"), {
           ...movie,
           created : serverTimestamp(),
           auther : state.isAuthenticate?.displayName,
           userId : state.isAuthenticate?.uid,
           watched : false,
           
        });  
      } catch (e) {
        console.error("Error adding document: ", e);
      } 
  };

  const removeMoviesFromWatchList = async(docId) => {     
      try{ 
          await deleteDoc(doc(db, "watchlist", docId)) 
          dispatch({ type: "REMOVE_MOVIES_FROM_WATCHLIST", payload: docId }); 
          toast.dismiss();
          toast.success("Removed From WatchList");
        }catch(err){
            console.log(err);
        } 
  };

  const addMoviestoWatchedList = async(id) => {   
    try { 
      await updateDoc(doc(db, "watchlist", id), {
         'watched' : true, 
      });   
      console.log("Document watched true ");
    } catch (e) {
      console.error("Error adding document: ", e);
    }  
  };



  // remove from watched

  const removeMoviesFromWatched = async(Id) => {  
     try{ 
        await deleteDoc(doc(db, "watchlist", Id)) 
        dispatch({ type: "REMOVE_MOVIES_FROM_WATCHED", payload: Id }); 
        toast.dismiss();
        toast.success("Removed From Watched List");
      }catch(err){
          console.log(err);
      } 
  };

// move from watched to watchlist

const movetoWatcheList = async(id) => {
   try { 
      await updateDoc(doc(db, "watchlist", id), {
         'watched' : false, 
      });   
      console.log("Document watched false ");
    } catch (e) {
      console.error("Error adding document: ", e);
    }   
  };

// add to favorites
const addMoviestoFavorites = (movie)=>{
  try {
   addDoc(collection(db, "favorites"), {
       ...movie,
       created : serverTimestamp(),
       auther : state.isAuthenticate?.displayName,
       userId : state.isAuthenticate?.uid 
    });  
  } catch (e) {
    console.error("Error adding document: ", e);
  }  
}

const removeMoviesFromFavorites = async(docId) => {   
   try{ 
      await deleteDoc(doc(db, "favorites", docId)) 
      dispatch({ type: "REMOVE_MOVIES_FROM_FAVORITES", payload: docId }); 
      toast.dismiss();
      toast.success("Removed From Favorites");
    }catch(err){
        console.log(err);
    }  
};


const removeMultiMoviesFromWatchlist = (batches) => {   
  try{ 
    const batch = writeBatch(db);  
      batches.forEach(docId=>{ 
        batch.delete(doc(db, "watchlist", docId.id))  
        dispatch({ type: "REMOVE_MOVIES_FROM_WATCHLIST", payload: docId.id });  
      }) 
      toast.success("Removed From WatchList");
   batch.commit(); 
  }catch(err){
      console.log(err);
  } 
 
}

const removeMultiMoviesFromWatchedlist = (batches) => {   
  try{ 
    const batch = writeBatch(db);  
      batches.forEach(docId=>{ 
        batch.delete(doc(db, "watchlist", docId.id))  
        dispatch({ type: "REMOVE_MOVIES_FROM_WATCHED", payload: docId.id });  
      }) 
      toast.success("Removed From WatchedList");
   batch.commit(); 
  }catch(err){
      console.log(err);
  } 
 
}

const removeMultiMoviesFromFavorites = (batches) => {   
  try{ 
    const batch = writeBatch(db);  
      batches.forEach(docId=>{ 
        batch.delete(doc(db, "favorites", docId.id))  
        dispatch({ type: "REMOVE_MOVIES_FROM_FAVORITES", payload: docId.id });  
      }) 
      toast.success("Removed From Favorites");
   batch.commit(); 
  }catch(err){
      console.log(err);
  } 
 
};

const addMultiMoviestoWatchedList = (batches) => {   
  try { 

    const batch = writeBatch(db);  
    batches.forEach(docId=>{ 
       batch.update(doc(db, "watchlist", docId.id), {
        'watched' : true, 
     })   
    }) 
    toast.success("Moved to watched List");
    batch.commit();   
  } catch (e) {
    console.error("Error adding document: ", e);
  }  
};


const addMultiMoviestoWatchList = (batches) => {   
  try { 

    const batch = writeBatch(db);  
    batches.forEach(docId=>{ 
       batch.update(doc(db, "watchlist", docId.id), {
        'watched' : false, 
     })   
    }) 
    toast.success("Moved to watchList");
    batch.commit();   
  } catch (e) {
    console.error("Error adding document: ", e);
  }  
};


// Logout
const handleLogout = (isAuthenticate)=>{
  signOut(auth)
  .then(()=>{ 
    dispatch({type: "IS_AUTHORISED", payload: isAuthenticate})  
    toast.success(`user logged off!`, 5000);  
  }).catch((error) => {
    console.log(error);
  });
}




  return (
    <GlobelContext.Provider
      value={{
        watchlist: state.watchlist,
        watched: state.watched,
        favorites: state.favorites,
        isAuthenticate : state.isAuthenticate,
        addMoviestoWatchList,
        removeMoviesFromWatchList,
        addMoviestoWatchedList,
        removeMoviesFromWatched,
        handleLogout,
        movetoWatcheList,
        addMoviestoFavorites,
        removeMoviesFromFavorites,
        removeMultiMoviesFromFavorites,
        removeMultiMoviesFromWatchlist,
        removeMultiMoviesFromWatchedlist,
        addMultiMoviestoWatchedList,
        addMultiMoviestoWatchList
      }}
    >
      {props.children}
    </GlobelContext.Provider>
  );
};
