import React from 'react'; 
import "./Footer.css"

function Footer(){
  return (
    <footer className="footer-area pt-50">
    <div className="footer">
        <div className="container">
            {/* <div className="footer-top">
                <div className="row">
                    <div className="col-sm-3 col-lg-3">
                        <div className="instagram-post gradient1">
                            <img src="image/instagram/1.jpg" alt=""/>
                            <a href="/" className="instagram-icon"><span className="fa fa-instagram"></span></a>
                        </div>
                    </div>
                    <div className="col-sm-3 col-lg-3">
                        <div className="instagram-post gradient1">
                            <img src="image/instagram/2.jpg" alt=""/>
                            <a href="/" className="instagram-icon"><span className="fa fa-instagram"></span></a>
                        </div>
                    </div>
                    <div className="col-sm-3 col-lg-3">
                        <div className="instagram-post gradient1">
                            <img src="image/instagram/3.jpg" alt=""/>
                            <a href="/" className="instagram-icon"><span className="fa fa-instagram"></span></a>
                        </div>
                    </div>
                    <div className="col-sm-3 col-lg-3">
                        <div className="instagram-post gradient1">
                            <img src="image/instagram/4.jpg" alt=""/>
                            <a href="/" className="instagram-icon"><span className="fa fa-instagram"></span></a>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="footer-center pt-50">
                <div className="ft-widgets">
                    <div className="row">
                        <div className="col-sm-6 col-lg-3">
                            <div className="ft-widget">
                                <div className="ft-widget-titles">
                                    <h2 className="ft-widget-title">Movies Category</h2>
                                </div>
                                <div className="ft-widget-contetn">
                                    <ul className='list-group list-unstyled'>
                                        <li><a href="/">Movies</a></li>
                                        <li><a href="/">Videos</a></li>
                                        <li><a href="/">English Movies</a></li>
                                        <li><a href="/">China Movies</a></li>
                                        <li><a href="/">Tailor</a></li>
                                        <li><a href="/">Upcoming Movies</a></li>
                                        <li><a href="/">Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="ft-widget">
                                <div className="ft-widget-titles no-text">
                                    <h2 className="ft-widget-title">information</h2>
                                </div>
                                <div className="ft-widget-contetn">
                                    <ul className='list-group list-unstyled'>
                                        <li><a href="/">About Us</a></li>
                                        <li><a href="/">Song</a></li>
                                        <li><a href="/">Forums</a></li>
                                        <li><a href="/">Place</a></li>
                                        <li><a href="/">Hot Collection</a></li>
                                        <li><a href="/">Upcoming Events</a></li>
                                        <li><a href="/">All Movies</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="ft-widget">
                                <div className="ft-widget-titles no-text">
                                    <h2 className="ft-widget-title">Locations</h2>
                                </div>
                                <div className="ft-widget-contetn">
                                    <ul className='list-group list-unstyled'>
                                        <li><a href="/">South Korea</a></li>
                                        <li><a href="/">Germany</a></li>
                                        <li><a href="/">United States</a></li>
                                        <li><a href="/">France</a></li>
                                        <li><a href="/">India</a></li>
                                        <li><a href="/">New Movies</a></li>
                                        <li><a href="/">United Kingdom</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="ft-widget">
                                <div className="ft-widget-titles">
                                    <h2 className="ft-widget-title">Newsletter</h2>
                                </div>
                                <div className="ft-widget-contetn">
                                    <div className="ft-newsletter">
                                        <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anytg embarrassing hidden.</p>
                                       
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="footer-buttom">
        <div className="container">
            <div className="row">
                <div className="col-sm-5 col-lg-6">
                    <div className="ft-menu">
                        <ul className='p-0'>
                            <li><a href="/">Terms</a></li>
                            <li><a href="/">Privacy</a></li>
                            <li><a href="/">About</a></li>
                            <li><a href="/">Our Ads</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-sm-7 col-lg-6">
                    <div className="ft-copyright">
                        <p>CopyRight 2022 Develop by <a href="/" className="c1">HS</a> . All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
  );
};

export default Footer;
