import React, { useState, useEffect } from "react";
import { Container} from "react-bootstrap";  
import { useLocation  } from 'react-router-dom';
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { baseUrl } from "../../../services/Config"; 
import MovieList from "../../Movies/MovieList"; 
import MovieSkeleton from "../../Movies/MovieSkeleton"; 
import SpinnerCircle from "../../SpinnerCircle"

const StreamingSoon = () => {
  
  // const [upcoming, setUpcoming] = useState([]); 
  // const [streamingNow, setStreamingNow] = useState([]); 
  const [items, setItems] = useState([]);
  const [isLoaded, setisLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);  
  const [hasMore, sethasMore] = useState(true);
   
  const Movies = `${baseUrl}/3/movie/now_playing?api_key=${process.env.REACT_APP_API_KEY}&page=${currentPage}&region=IN`; 
  const tv = `${baseUrl}/3/tv/on_the_air?api_key=${process.env.REACT_APP_API_KEY}&page=${currentPage}&region=IN`; 
  
  const fetchData = (setItems,items)=> {  
    try {
    axios
        .all([axios.get(Movies),axios.get(tv)])
        .then(
          axios.spread((one, two) => {   
            setItems([...items, ...one.data.results, ...two.data.results]);  
            setCurrentPage(currentPage + 1) 

            if(one.data.total_results === items.length){
              sethasMore(false)
            }

            // const notReleased = one.data.results.filter((movie)=>{
            //   const today = new Date().toISOString().slice(0, 10);
            //     return movie?.release_date > today
            // }) 

            // const Released = one.data.results.filter((movie)=>{
            //   const today = new Date().toISOString().slice(0, 10);
            //     return movie?.release_date <= today
            // }) 

            // category === "upcoming"  && setUpcoming(notReleased); 
            // category === "now_playing" && setStreamingNow(Released)
            setisLoaded(true)
          })
        );
    } catch (error) {
      console.log(error);
    }
  };
 
  useEffect(() => {  

      fetchData(setItems,items);  
    
  }, []); 

    return ( 
        <>
            <div className='mainHead'>
            <Container>
                <h2 className='text-center text-white mt-4'>Streaming Now</h2>
            </Container> 
            </div>  


            <InfiniteScroll
     dataLength={items.length} //This is important field to render the next data
     next={() => {
      setTimeout(()=>{  
        fetchData(setItems, items);
      },1000)
     }}
     hasMore={hasMore}
     loader={<SpinnerCircle/>}
     scrollThreshold={0.6}
     endMessage={
       <p style={{ textAlign: "center" }}>
         <b>Yay! You have seen it all</b>
       </p>
     }
      
   >
     {
               <>
                {!isLoaded && <MovieSkeleton col="2" count={18}  height={245} width={166} classEls="skeltonMobile"/> }
             
                <MovieList
                title=""
                data={[items]}
                col="2" 
                classEl="card-sm"
                type="basic"
                />
               </>
               
           } 
   </InfiniteScroll> 
        </>
    );
}

export default StreamingSoon;
