import React, {useState, useEffect} from 'react'; 
import axios from 'axios';  
import { baseUrl,imagePath } from '../../services/Config'; 
import MovieControls from '../Pages/watchlist/MovieControls';
import Skeleton from "react-loading-skeleton";
import { Link } from 'react-router-dom';
import Slider from "react-slick"; 
import { settings } from "./SliderSettings";
import { FaStar } from "@react-icons/all-files/fa/FaStar"; 
import "./banner.css" 

const Banner = () => { 


    const [trending,setTrending] = useState([]);
    const [isLoaded,setIsLoaded] = useState(false);

    const fetchTrending = async ()=>{

        const {data} = await axios.get(`${baseUrl}/3/trending/all/day?api_key=${process.env.REACT_APP_API_KEY}&language=en-IN`)
        setTrending(data)
        setIsLoaded(true)
    }

    useEffect(() => {  
          
        fetchTrending();
        
    
    }, []); 
 

  return (
    <div className="container">  
        <Slider {...settings} className="mt-4"> 

            {     

        isLoaded ?  

                trending.results?.map(item=>{  
                    const rating = item?.vote_average.toString().slice(0,3); 

                    return (
                    <Link to={`movie/${item?.id}`} state={{ data : item }} key={item?.id}>
                        <div className='bannerImg'>
                            <img src={imagePath+item?.poster_path} alt={item?.title} className="img-fluid" />
                            

                            <div className="position-absolute bottom-0 pb-2 pl-3 movie-prop d-flex justify-content-between"> 
                                <div>
                                    {rating !== "0" && <p className='m-0 d-flex align-items-center'>
                                  <FaStar className="text-warning fs-6" />
                                    <span className='fs-5  ml-2'>{rating}</span>/10
                                </p> }
                                
                                    <div className="product-title text-uppercase">
                                        <strong>{item?.title || item?.name}</strong>
                                    </div> 
                                    <div className="movie-title product-meta font-size-12">
                                        <span>{item?.release_date?.substring(0, 4) || item?.first_air_date?.substring(0, 4)} </span> 
                                    </div>
                                </div>
                               
                            </div>
                        </div> 
                    </Link>
                    )
                })
                : Array(10).fill(0).map((item, index)=><Skeleton height={400} width={270} key={index}/>) 

              
            }
          
        </Slider>
      </div>
  );
};

export default Banner;
