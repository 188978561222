import React from 'react'

function Spinner() { 
  return (
    
    <>
       <div className="spinner">
        <div className="boxes">
          <div className="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
          </div>
          <div className="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
          </div>
          <div className="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
          </div>
          <div className="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
          </div>
      </div> 
      </div> 
    </>
    
   
  )
}

export default Spinner