import { React, useContext } from "react";
import { HashRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import {GlobelContext} from "./components/Context/GlobelState"; 
import { ToastContainer, Slide } from 'react-toastify';
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from "./components/Home";
import Watchlist from "./components/Pages/watchlist/Watchlist";
import Watched from "./components/Pages/watchlist/Watched";
import Favorites from "./components/Pages/watchlist/Favorites";
import NotFound from "./components/Pages/NotFound";
import Header from "./components/Head/Header";
import Single from "./components/Movies/Single";
import Person from "./components/Person";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import StreamingSoon from "./components/Pages/Streaming/StreamingSoon";
import StreamingNow from "./components/Pages/Streaming/StreamingNow";
import FirebaseTest from "./components/Pages/FirebaseDemo/FirebaseTest";
import 'react-toastify/dist/ReactToastify.css';
import Seasons from "./components/Tv/Seasons";

function App() {

  const {isAuthenticate} = useContext(GlobelContext)  

  return (
    <> 
        <SkeletonTheme baseColor="#898989" highlightColor="#5e5e5e">
        <ToastContainer
              position="bottom-center"
              autoClose={3000}
              hideProgressBar
              newestOnTop={true}
              closeOnClick={true}
              transition={Slide}
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
              className = 'toast-message'
            />
          <Router>
            <Routes>
              <Route path="/" element={<Header />}>
                <Route index element={<Home />} />
                <Route path="movie/:matchId" element={<Single />} />
                <Route path="season/:matchId" element={<Seasons />} />
                <Route path="Person/:matchId" element={<Person />} />
                <Route path="StreamingSoon" element={<StreamingSoon />}>
                  <Route path=":matchId" element={<Single />} />
                </Route>
                <Route path="StreamingSoon" element={<StreamingSoon />}>
                  <Route path=":matchId" element={<Single />} />
                </Route>
                <Route path="StreamingNow" element={<StreamingNow />}>
                  <Route path=":matchId" element={<Single />} />
                </Route>
                <Route path="Watchlist" element={isAuthenticate ? <Watchlist /> : <Navigate to="/"/>} />
                <Route path="Watched" element={isAuthenticate ? <Watched /> : <Navigate to="/"/>}/>
                
                <Route path="Favorites" element={isAuthenticate ? <Favorites /> : <Navigate to="/"/>} /> 
                <Route path="Firebase" element={<FirebaseTest />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </Router>
        </SkeletonTheme> 
    </>
  );
}

export default App;
