import React, {useState} from 'react';
import axios from 'axios';

const AddPost = ({onPostAded}) => {

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')   

    const postHandler = (e)=>{
        e.preventDefault();
        const postData = {
            title, 
            description,
        }  
        axios.post('https://movie-watchlist-cca6a-default-rtdb.firebaseio.com/posts.json', postData)
        .then(res =>{   
            onPostAded()
            setTitle("")
            setDescription("")
            
        }) 
    }

     


    return (
        <div className='form-group p-4 border bg-dark bg-opacity-10'>
           <form onSubmit={postHandler}>
            <div className="mb-3">
                    <label htmlFor="titleInput" className="form-label">Title</label>
                    <input type="text" value={title}  id='titleInput' onChange={(e)=>setTitle(e.target.value )} className="form-control" required/>
                </div>
                <div className="mb-3">
                    <label htmlFor="Description" className="form-label">Description</label>
                    <textarea onChange={(e)=>setDescription(e.target.value)} className="form-control" id="Description" rows="3" value={description} required></textarea>
                </div>
                <div className="mb-0">
                <button type="submit" className="btn btn-primary mb-3">Submit</button>
                </div>
           </form>
        </div>
    );
}

export default AddPost;
