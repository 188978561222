import {initializeApp} from "firebase/app"
import {getFirestore} from "firebase/firestore"
import {getStorage} from "firebase/storage"
import {getAuth} from "firebase/auth"

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAj3M4U_icz0ZTm6NJ4jqoT3LZzZ0mB98g",
    authDomain: "movie-watchlist-cca6a.firebaseapp.com",
    databaseURL: "https://movie-watchlist-cca6a-default-rtdb.firebaseio.com",
    projectId: "movie-watchlist-cca6a",
    storageBucket: "movie-watchlist-cca6a.appspot.com",
    messagingSenderId: "1022684272453",
    appId: "1:1022684272453:web:5e63590f484755d166502c"
  };
 

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)

export {auth, db, storage}
