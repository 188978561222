import React, {Suspense} from 'react';
import { Container} from "react-bootstrap"; 
import { useLocation } from 'react-router-dom';
import MovieSkeleton from '../Movies/MovieSkeleton';
const Tvlist = React.lazy(() => import('./TvList'));  

const Seasons = () => {

    const location = useLocation();
    const seasons = location?.state?.data;
    const match = location?.state?.match;
    
    return (
        <>
        <div className='mainHead'>
               <Container>
                   <h2 className='text-center text-white mt-4 display-6'>
                       {match && match?.name} <small>{match.first_air_date && `(${match.first_air_date.toString().slice(0,4)})`}</small>
                   </h2>
                    
               </Container> 
           </div> 
           <Container> 
             <Suspense fallback={<MovieSkeleton
                       col="12"
                       count={5} 
                       height={245}
                       width={166}
                       />}>
               <Tvlist
                 data={[seasons]}
                 col="12" 
                 classEl="card-person"
                 />
             </Suspense>
           </Container>
       </>
    );
}

export default Seasons;
