import React from 'react';
import Modal from 'react-bootstrap/Modal';

const YouTubeModal = (props) => {  
    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter" 
        centered

      > 
        <Modal.Body className='bg-black'> 
           {props.rendertrailer()}
        </Modal.Body> 
      </Modal>
    );
}

export default YouTubeModal;
