import React, { useRef, useState, useContext, useEffect } from "react";
import { Badge, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import Search from "../Search/Search";
import Logo from "./logo.png";
import "./Header.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Auth from "../Pages/Auth";
import { GlobelContext } from "../Context/GlobelState";
import HeaderMobile from "./HeaderMobile";
import StickyBottom from "../Footer/StickyBottom";

function Header() {
  const [modalShow, setModalShow] = useState(false);
  const collapseRef = useRef(null);
  const [searchShow, setSearchShow] = useState(false);

  const { handleLogout, isAuthenticate, watchlist, watched, favorites } =
    useContext(GlobelContext);

  const hideBars = () => {
    collapseRef.current.setAttribute("class", "navbar-collapse collapse");
  };

  return (
    <>
      <header className="position-absolute w-100">
        <Navbar collapseOnSelect expand="lg" variant="dark">
          <Container>
            <NavLink to="/" className="navbar-brand">
              <img src={Logo} alt="..." />
            </NavLink>
            <HeaderMobile
              setSearchShow={setSearchShow}
              searchShow={searchShow}
              setModalShow={setModalShow}
            />

            <Navbar.Collapse
              id="responsive-navbar-nav"
              ref={collapseRef}
              className="d-none d-lg-block"
            >
              <Nav className="me-auto">
                <NavLink to="/" className="nav-link" onClick={hideBars}>
                  Home
                </NavLink>
                <NavLink
                  to="StreamingNow"
                  className="nav-link"
                  onClick={hideBars}
                >
                  Streaming Now
                </NavLink>
                <NavLink
                  to="StreamingSoon"
                  className="nav-link"
                  onClick={hideBars}
                >
                  Streaming Soon
                </NavLink>
                {/* <NavLink to="Firebase" className="nav-link">
                  Firebase
                </NavLink> */}
              </Nav>
              <Nav>
                {!isAuthenticate ? (
                  <NavLink
                    className="nav-link"
                    variant="primary"
                    onClick={() => setModalShow(true)}
                  >
                    Login
                  </NavLink>
                ) : (
                  <NavDropdown
                    title={
                      isAuthenticate?.displayName
                        ? isAuthenticate?.displayName
                        : "Loading..."
                    }
                    id="collasible-nav-dropdown"
                  >
                    <NavDropdown.Item as="li">
                      <NavLink
                        to="#"
                        className="d-block text-decoration-none" 
                      >
                        <i className="bi-person-bounding-box"></i> Profile 
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item as="li">
                      <NavLink
                        to="Watchlist"
                        className="d-block text-decoration-none"
                        onClick={hideBars}
                      >
                        <i className="bi bi-bookmark-plus"></i> Watchlist{" "}
                        <Badge bg="success" className="bg-gradient">
                          {watchlist[0]?.length}
                        </Badge>
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item as="li">
                      <NavLink
                        to="Watched"
                        className="d-block text-decoration-none"
                        onClick={hideBars}
                      >
                        <i className="bi bi-bookmarks"></i> Watched{" "}
                        <Badge bg="success" className="bg-gradient">
                          {watched[0]?.length}
                        </Badge>
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item as="li">
                      <NavLink
                        to="Favorites"
                        className="d-block text-decoration-none"
                        onClick={hideBars}
                      >
                        <i className="bi bi-heart"></i> Favorites{" "}
                        <Badge bg="success" className="bg-gradient">
                          {favorites[0]?.length}
                        </Badge>
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => handleLogout()}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Search isSearch={searchShow} />
      </header>

      <Outlet />

      <Footer />

     <StickyBottom/>

      <Auth show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default Header;
