import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";  
import axios from "axios";
import { baseUrl, imagePath } from "../../services/Config";
import Skeleton from "react-loading-skeleton";   
import MovieList from "../Movies/MovieList"; 

const Index = () => {

  

  const { matchId } = useParams();
  const [person, setPerson] = useState();
  const [knownFor, setKnownFor] = useState(); 
  const [isLoaded, setisLoaded] = useState(false);   
  const [isExpand, setisExpand] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);   

    const personApi = `${baseUrl}/3/person/${matchId}?api_key=${process.env.REACT_APP_API_KEY}&language=en-US&page=1`;

    const personApiSearch = `${baseUrl}/3/person/${matchId}/combined_credits?api_key=${process.env.REACT_APP_API_KEY}&language=en-US`;
     

    const fetchData = ()=> {
      try {
        axios
          .all([axios.get(personApi), axios.get(personApiSearch)])
          .then(
            axios.spread((one, two) => { 
                const MoveCredits = two.data?.cast.filter(item=> item.poster_path && item?.popularity > 2.1 ) 
                setPerson(one.data); 
                setKnownFor(MoveCredits);   
              setisLoaded(true)
            })
          );
      } catch (error) {
        console.log(error);
      }
    };
 
      fetchData();  

    
    
  }, [matchId]);
    
  const getAge = birthDate => Math.floor((person?.deathday !== null ? new Date(person?.deathday) : new Date()  - new Date(birthDate).getTime()) / 3.15576e+10)
  
  const age = getAge(person?.birthday)   
 
    return (
      <>
        <div
          className="mainHead singleMovie"
          style={{
            backgroundImage: `url(${
              person?.backdrop_path
                ? imagePath + person?.backdrop_path
                : "/assets/images/slider-bg.jpg"
            })`,
          }}
        >
          <div className="content">
            <div className="container">
              <div className="row mt-4">
                <div className="col-md-3 col-sm-12 col-6">
                  <div className="movie-img sticky-sb">
                    {isLoaded && (
                      <img
                        src={
                          person?.profile_path
                            ? imagePath + person?.profile_path
                            : "/assets/images/image-not-found.JPG"
                        }
                        className="img-fluid"
                        alt={person?.title || person?.name}
                      />
                    )}
                    {!isLoaded && <Skeleton height={390} width={261} />}
                        

                  </div>
                </div>
                <div className="col-md-9 col-sm-12 col-xs-12">
                  <div className="movie-single-ct main-content">
                    <h1 className="bd-hd">
                      {person?.title || person?.name || <Skeleton />}{" "}
                      <span>{person?.known_for_department}</span>
                    </h1>

                    <div className="row">
                      <div className="col-12">
                        {person?.birthday && (
                            <h6 className="text-white">
                            <strong className="d-block">Birthday </strong>
                            <span className="font-weight">
                              {person?.birthday}
                            </span>
                            {person?.deathday === null && (
                              <small className="font-weight">
                                {" "}
                                {age ? `(${age} Year's Old)` : ""}
                              </small>
                            )}
                          </h6>
                        )}
                        

                        {person?.deathday && (
                          <h6 className="text-white">
                            <strong className="d-block">Died </strong>
                            <span className="font-weight">
                              {person?.deathday}
                            </span>
                            <small className="font-weight">
                              {" "}
                              {`(${
                                person?.deathday.slice(0, 4) -
                                person?.birthday.slice(0, 4)
                              } Year's Old)`}
                            </small>
                          </h6>
                        )}

                        {person?.place_of_birth && (
                            <h6 className="text-white">
                            <strong className="d-block">Place of Birth </strong>
                            <span className="font-weight">
                              {person?.place_of_birth}
                            </span>
                          </h6>
                        )}
                        
                        {person?.biography && ( 
                          <>
                            <strong className="d-block text-white">
                              Biography
                            </strong>
                            <div
                              className={`position-relative ${
                                !isExpand && "long-paragraph"
                              }`}
                            >
                              <div className="fade-text">
                                {person?.biography
                                  .split("\n")
                                  .map((paragraph, index) => {  
                                    return (
                                      <p
                                        key={index}
                                        className="small font-weight text-white"
                                      >
                                        {paragraph}
                                      </p>
                                    );
                                  }) || <Skeleton count={4} />}
                                <div
                                  className={`read_more ${ 
                                   (person?.biography.length < 1120  || isExpand) && "d-none"  
                                  }`}
                                >
                                  <div
                                    className="read_more_Click"
                                    onClick={() => setisExpand(true)}
                                  >
                                    Read More{" "}
                                    <span className="bi bi-chevron-right"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    {knownFor && knownFor.length !== 0 && (
                      <div className="row g-3">
                        <h6 className="mt-2 text-white bold mb-0 mt-3">
                          Known For
                        </h6>
                        <MovieList
                          data={[knownFor]}
                          col="2"
                          count=""
                          classEl="card-person"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default Index;
