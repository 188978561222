import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail
} from "firebase/auth";
import { auth } from "../../Firebase";

const Auth = (props) => {
  const initialState = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmpassword: "",
  };
  const [state, setState] = useState(initialState);
  const [signup, setSignup] = useState(false);
  const [reset, setReset] = useState(false);

  const { firstname, lastname, email, password, confirmpassword } = state;

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleAuth = async (e) => {
    e.preventDefault();
    if (!signup) {
      if(reset){
        if (email){
          sendPasswordResetEmail(auth, email)
          .then(() => {
            // Password reset email sent!
            toast.success(`Password reset email sent!`, 5000); 
            setSignup(false)
            setReset(false)
          })
          .catch((e) => {
            const errorCode = e.code;
            const errorMessage = e.message;
            console.log(errorMessage);
            if (e instanceof Error) {
              if (e.code.includes("auth/user-not-found")) {
                toast.error("User not Found Please verify", 7000);
              } else {
                toast.error(errorMessage, 7000);
              }
            }
          });
        }
      } else{
        if (email && password) {
          signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              // Signed in
              props.onHide() 
              const user = userCredential.user; 
              toast.success(`Welcome ${user.displayName}`, 5000); 
              // ...
            })
            .catch((e) => {
              const errorMessage = e.message; 
              if (e instanceof Error) {
                if (e.code.includes("auth/user-not-found")) {
                  toast.error("User not Found Please Sign-up", 7000);
                } 
                else if(e.code.includes("auth/wrong-password")){
                  toast.error("invalid Email/Password", 7000);
                }
                else if(e.code.includes("auth/invalid-emai")){
                  toast.error("invalid Email", 7000);
                } else {
                  toast.error(errorMessage, 7000);
                }
              }
            });
        } 
      }
      
    } else {
      if (password.length < 6) {
        return toast.warn("Password should be at least 6 characters");
      }
      if (password !== confirmpassword) {
        return toast.error("Password not matching!");
      }
      if (firstname && lastname && email && password) {
        await createUserWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            const user = userCredential.user;
            updateProfile(user, { displayName: `${firstname} ${lastname}` });
            toast.success("Great! Account succesfully created");   
            setSignup(false);
          }) 
          .catch((e) => {
            const errorMessage = e.message; 
            if (e instanceof Error) {
              if (e.code.includes("auth/email-already-in-use")) {
                toast.warn(
                  "Email already in use please try another mail id!",
                  5000
                );
              }
              else if (e.code.includes("auth/invalid-email")) {
                toast.error("invalid Email/Password", 5000);
              } else {
                toast.error(errorMessage, 7000);
              }
            }
          });
      } else {
        return toast.warn("All fields are should be filled");
      }
    }
  }; 
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      dialogClassName="login-modal"
    >
      <Modal.Header closeButton className="border-0">
        {/* <Modal.Title className='text-uppercase fs-6'>Sign-In</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <Form className="col-9 m-auto" onSubmit={handleAuth}>
          <h4 className="text-uppercase mb-4 fs-3 font-weight">
            {!reset ? signup ? "Sign-Up" : "Sign-In" : "Reset password"}
          </h4>

          {signup && (
            <>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="text-white-50">First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  name="firstname"
                  value={firstname}
                  className="border-0 rounded-0 form-control-lg fs-6 text-white-50 py-3"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label className="text-white-50">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  name="lastname"
                  value={lastname}
                  className="border-0 rounded-0 form-control-lg fs-6 text-white-50 py-3"
                  onChange={handleChange}
                />
              </Form.Group>
            </>
          )}

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
            <Form.Label className="text-white-50">Email Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Email Address"
              name="email"
              value={email}
              required
              className="border-0 rounded-0 form-control-lg fs-6 text-white-50 py-3"
              onChange={handleChange}
            />
          </Form.Group>
          {!reset && (
            <>
               <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
            <Form.Label className="text-white-50">Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={handleChange}
              className="border-0 rounded-0 form-control-lg fs-6 text-white-50 py-3"
            />
          </Form.Group> 
            </> 
          )}
          
          {
            !reset && !signup && (
              <small  
              className="text-decoration-underline cursorPointer"
              onClick={()=>setReset(true)}
              >
                   Forgot Password? 
                  </small>
            )
          }

          {signup && (
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
              <Form.Label className="text-white-50">
                Confirm Password
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                name="confirmpassword"
                value={confirmpassword}
                onChange={handleChange}
                className="border-0 rounded-0 form-control-lg fs-6 text-white-50 py-3"
              />
            </Form.Group>
          )}
          <Button
            type="submit"
            size="md"
            className={`submitBTN mb-lg-3 mt-3 ${
              !signup && !reset ? "bg-success" : "bg-warning text-dark"
            }`}
          >
            {!reset ? signup ? "Sign-Up" : "Sign-In" : "Reset Password"}
          </Button>
          <div className="mb-lg-5 text-white-50 mt-3">
            {signup ? (
              <small>
                Already have a account?{" "}
                <span
                  onClick={() => setSignup(false)}
                  className="text-warning text-decoration-underline cursorPointer "
                >
                  Sign-In
                </span>
              </small>
            ) : (
               
              !reset ? (
                <small>
                Don't have an account?{" "}
                <span
                  onClick={() => setSignup(true)}
                  className="text-warning text-decoration-underline cursorPointer "
                >
                  Sign-Up
                </span>
              </small>
              ) : <small className="text-decoration-underline cursorPointer" onClick={() => {setSignup(false); setReset(false)} }>Back to Sign-in</small>
              
            )}
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Auth;
