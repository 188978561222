import React, {useContext} from 'react';
import { GlobelContext } from '../../Context/GlobelState';
import { Container} from "react-bootstrap";
import MovieList from '../../Movies/MovieList';
 
const Watched = () => {

    const {watched} = useContext(GlobelContext)
 

    return (
        <>
         <div className='mainHead'>
                <Container>
                    <h2 className='text-center text-white mt-4 display-6'>My Watched List </h2>
                    <p className='text-center'><span className='bg-gradient fs-6 p-2 text-white'><strong className='text-warning'> {watched ? watched[0]?.length : "0"} </strong> {watched[0]?.length > 1 ? "Movies" : "Movie"}</span></p>
                </Container> 
            </div> 
            <Container> 
                <MovieList data={watched} col="2" count="100" classEl="card-sm" type="watched"  sort="true"/>
            </Container>
        </>
           
    );
}

export default Watched;
