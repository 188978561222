import React, {useContext, Suspense} from 'react';
import { GlobelContext } from '../../Context/GlobelState';
import { Container} from "react-bootstrap";
import MovieSkeleton from '../../Movies/MovieSkeleton';
const MovieList = React.lazy(() => import('../../Movies/MovieList')); 

 
const Favorites = () => {

    const {favorites} = useContext(GlobelContext)

    return (
        <>
         <div className='mainHead'>
                <Container>
                    <h2 className='text-center text-white mt-4 display-6'>
                        My Favorites  
                    </h2>
                    <p className='text-center'><span className='bg-gradient fs-6 p-2 text-white'><strong className='text-warning'> {favorites[0]?.length} </strong> {favorites[0]?.length > 1 ? "Movies" : "Movie"}</span></p>
                </Container> 
            </div> 
            <Container> 
              <Suspense fallback={<MovieSkeleton
                        col="2"
                        count={12} 
                        height={245}
                        width={166}
                        />}>
                <MovieList data={favorites} col="2" count="100" classEl="card-sm"  type="favorites" sort="true" />
              </Suspense>
            </Container>
        </>
           
    );
}
 

export default Favorites;


