import React from "react";

const NotFound = () => {
  return (
    <div className='mainHead'>
      <div className="container  text-center text-white mt-4">
        <h1 className="fa-10x mt-5 pt-5">404</h1>
        <h6>Page Not Found</h6>
      </div> 
    </div>
  );
};

export default NotFound;
