import { useContext, useState } from "react";
import { imagePath } from "../../services/Config";
import { Container, Card, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import MovieControls from "../Pages/watchlist/MovieControls";
import SpinnerCircle from "../SpinnerCircle";
import "./movielist.css";
import { GlobelContext } from "../Context/GlobelState";
import { toast } from "react-toastify";

const Movielist = (props) => {
  const [sort, setSort] = useState("All");
  const [isSelected, setIsSelected] = useState(false);
  const [selectItem, setSelectItem] = useState([]);
  const [data] = props.data;
  const navigate = useNavigate();
  const { 
    removeMultiMoviesFromFavorites,
    removeMultiMoviesFromWatchlist,
    removeMultiMoviesFromWatchedlist,
    addMultiMoviestoWatchedList,
    addMultiMoviestoWatchList } = useContext(GlobelContext); 

  const sortBy = (e) => {
    setSort(e.target.value);
  };
     
 
  const removeHandle = (id) => {
    setSelectItem([...selectItem, { id: id }]);
  };
  const removeItemfromSelction = (id) => {
    const removeItem = selectItem.filter((item) => item.id !== id);
    setSelectItem([...removeItem]);
  };

   
  return (
    <Container>
      {props?.title && (
        <div className="d-flex justify-content-between align-items-baseline">
          <h3 className="mt-5">{props?.title}</h3>
          {props?.title === "Upcoming Releases" && (
            <Link
              to="StreamingSoon"
              state={{ category: "upcoming", title: "Streeming Soon" }}
              className="font-weight-bold  text-decoration-none"
            >
              View More <em className="bi bi-chevron-right"></em>
            </Link>
          )}
        </div>
      )}

      {data && data.length !== 0 && props.sort && (
        <div className="row">
          <div className="col">
            <div className="d-flex justify-content-end mt-3 align-items-center gap-2">
              <label className="small">Sort by </label>
              <select className="form-select-sm" onChange={sortBy}>
                <option>All</option>
                <option value="movie">Movie</option>
                <option value="tv">TV</option>
              </select>
            </div>
          </div>
        </div>
      )}

      {data && data.length !== 0 && (props.type === "favorites" || props.type === "watchlist" || props.type === "watched" ) && (
        <div className="row">
          <div className="col">
            {!isSelected && (
              <div className="d-flex d-lg-none justify-content-between mt-3 align-items-center gap-2">
                <strong className="text-capitalize">{props?.type}</strong>
                <button onClick={() => setIsSelected(true)} className="btn p-0">
                  <i className="bi bi-pencil-square fs-4 text-primary"></i>
                </button>
              </div>
            )}
            {isSelected && props.type === "favorites"  && (
              <div className="align-items-center bg-gradient bg-warning d-flex gap-2 justify-content-between mt-3 p-3 py-1 text-white">
                <div>
                  <button
                    onClick={() => {
                      setIsSelected(false);
                      setSelectItem([]);
                    }}
                    className="btn fs-5 p-0 text-white mr-2"
                  >
                    {" "}
                    <i className="bi bi bi-x-lg"></i>
                  </button>
                  <small>Select Items to Remove</small>
                </div>{" "}
                <button
                  className="btn fs-5 p-0 text-white"
                  onClick={() => {
                    if (selectItem.length !== 0) {
                      removeMultiMoviesFromFavorites(selectItem); 
                      setIsSelected(false);
                      setSelectItem([]);
                    } else {
                      toast.warn("Please select movies");
                    }
                  }}
                >
                  <i className="bi bi-trash"></i>
                </button>
              </div>
            )}

            {isSelected && ( props.type === "watchlist" || props.type === "watched" ) && (
              <> 
               <div className="align-items-center d-flex gap-2 justify-content-end mt-3">
               
              <div className="align-items-center bg-gradient bg-success d-flex gap-2 justify-content-between p-3 py-1 text-white rounded-1"  onClick={()=>{ 
                  if (selectItem.length !== 0) {
                    props.type === "watchlist" &&  addMultiMoviestoWatchedList(selectItem);
                    props.type === "watched" &&  addMultiMoviestoWatchList(selectItem);
                        setIsSelected(false);
                        setSelectItem([]);
                    }else{
                      toast.warn("Please select movies");
                    }
                  }
                  }>
                <div>
                  
                  <small>{props.type === "watchlist" ? "Watched" : "Not Watched"}  </small>
                </div>{" "}
                <button className="btn fs-5 p-0 text-white">
                   {props.type === "watchlist" ? (<i className="bi bi-eye"></i>) : (<i className="bi bi-eye-slash"></i>) } 
                </button>
              </div> 

              <div className="align-items-center bg-gradient bg-secondary d-flex gap-2 justify-content-between p-3 py-1 text-white rounded-1"  onClick={() => {
                    if (selectItem.length !== 0) { 
                      props.type === "watchlist" &&  removeMultiMoviesFromWatchlist(selectItem); 
                      props.type === "watched" &&  removeMultiMoviesFromWatchedlist(selectItem); 
                      setIsSelected(false);
                      setSelectItem([]);
                    } else {
                      toast.warn("Please select movies");
                    }
                  }}>
                <div>
                 
                  <small>Remove</small>
                </div>{" "}
                <button
                  className="btn fs-5 p-0 text-white" >
                  <i className="bi bi-trash"></i>
                </button>
              </div>


              <button
                    onClick={() => {
                      setIsSelected(false);
                      setSelectItem([]);
                    }}
                    className="btn fs-5 p-0"
                  >
                    {" "}
                    <i className="bi bi bi-x-lg"></i>
                  </button>
               </div>
              </>
             
            )}
            
          </div>
        </div>
      )}

      {!data ? (
        <SpinnerCircle />
      ) : (
        data.length === 0 && (
          <>
            <h3 className="text-center mt-3">
              No Movies in your list, addsome!
            </h3>
            <div className="row">
              <div className="col-lg-4 m-auto">
                <img
                  src="/assets/images/Movies-not-found.gif"
                  className="img-fluid mt-4"
                  alt="Movies-not-found"
                />
              </div>
            </div>
          </>
        )
      )}

      <div
        className={`${
          props?.classEl === "card-person"
            ? "horizontal_media_list gap-3 mt-3"
            : "mt-4 row"
        }`}
      >
        {data &&
          data
            .filter((item, indx, self) => {
              return (
                (props.count ? indx < props.count : true && item.poster_path) &&
                (sort !== "All" ? item.media_type === sort : true) &&
                (indx === self.findIndex((t) => (t.id === item.id )))
                ); 
            })
            .sort((a, b) =>
              a.created ? b?.created.toDate() - a?.created.toDate() : true
            )
            .map((item) => {
              const rDate =
                item?.release_date?.toString().slice(0, 4) ||
                item?.first_air_date?.toString().slice(0, 4);

              let SelectedMovie = selectItem?.find(
                (obj) => obj?.id === (item?.docmtId || item?.docId)
              ); 
              const desableSelectedMovie = SelectedMovie ? true : false;
              return (
                <Col
                  xs="4"
                  lg={props.col}
                  className={props?.classEl === "card-person" ? "" : "mb-4"}
                  key={item?.id}  
                >
                  <div
                    onClick={() => {
                      isSelected
                        ? desableSelectedMovie
                          ? removeItemfromSelction(item?.docmtId || item?.docId)
                          : removeHandle(item?.docmtId || item?.docId)
                        : navigate(`/movie/${item?.id}`, {
                            state: { data: item },
                          });
                    }}
                    className="text-decoration-none text-dark cursorPointer"
                  >
                    <Card
                      className={`shadow border-0 overflow-hidden ${
                        props.classEl
                      } ${desableSelectedMovie && "selctedMovie"}`}
                    >
                      <MovieControls
                        movie={item}
                        type={props.type}
                        isSelected={isSelected}
                        desableMovie={desableSelectedMovie}
                      />
                      {/* <div className="position-absolute bg-white shadow-sm rounded text-center p-2 m-2 love-box">
                    <h6 className="text-gray-900 mb-0 font-weight-bold">
                      <FontAwesomeIcon icon={faHeart} className="text-danger" />{" "}
                      88%
                    </h6>
                    <small className="text-muted">{item?.popularity}</small>
                  </div> */}
                      <Card.Img
                        className="card-img-top rounded-top"
                        src={item?.poster_path ? imagePath + item?.poster_path : "/assets/images/not-available-poster.jpg"}
                        alt={item?.title || item?.name}
                      />
                      <Card.Body>
                        <Card.Title>{item?.title || item?.name}</Card.Title>
                        <Card.Text>
                          {item?.vote_average !== 0 && (
                            <small
                              className={`text-uppercase px-1 fa-border small mr-2 font-monospace ${
                                item?.vote_average >= 8
                                  ? "text-bg-success"
                                  : "text-bg-warning"
                              }`}
                            >
                              {item?.vote_average.toString().slice(0, 3)}
                            </small>
                          )}
                          <small className="d-inline-block">{`${rDate}`}</small>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              );
            })}
      </div>
    </Container>
  );
};

export default Movielist;
