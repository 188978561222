import React from 'react';
import { Spinner } from 'react-bootstrap';

const Index = () => {
    return ( 
            <div className="text-center my-5">
              <Spinner animation="border" role="status" variant="primary" >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>  
    );
}

export default Index;
